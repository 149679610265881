import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector"

const resources = {
   // cspell:dictionaries:en
   en: {
      translation: {
         Generic_Close: "Close",
         Generic_Cancel: "Cancel",
         Generic_Delete: "Delete",
         Generic_Ok: "Ok",
         Generic_All: "All",
         LoadingComponent_Loading: "Loading Application...",
         AppBar_Main: "Main",
         AppBar_Destinations: "Destinations",
         AppBar_Settings: "Settings",
         AppBar_Routing: "Routing",
         AppBar_OpenSettings: "Open settings",
         AppBar_About: "About",
         AppBar_Logout: "Logout",
         AppBar_OneCoreGoVersionInfo: "OneCoreGo Version Info",
         WelcomePage_Welcome: "Welcome",
         WelcomePage_SignOut: "Sign out",
         WelcomePage_CloseAccount: "Delete Account",
         DestinationPage_NewDestination: "New destination",
         DestinationPage_ClearAll: "Delete all",
         DestinationPage_ShowAll: "Show all",
         DestinationPage_NoDestinations: "No destinations saved",
         SettingsPage_NewDestination: "New setting",
         SettingsPage_ClearAll: "Delete all",
         SettingsPage_ShowAll: "Show all",
         SettingsPage_NoDestinations: "No settings saved",
         RoutingInput_AddDestination: "Add destination",
         RoutingInput_ClearRoute: "Clear route",
         RoutingInput_PosSearch_StartPosition: "Start Position",
         RoutingInput_PosSearch_Waypoint: "Waypoint",
         RoutingInput_PosSearch_Destination: "Destination",
         Avoid_HIGHWAYS_MOTORWAYS: "Avoid Highway/Motorway",
         Avoid_TOLL_ROADS: "Avoid Toll Roads",
         Avoid_FERRY: "Avoid Ferry/Car-train",
         Avoid_TUNNELS: "Avoid Tunnel",
         VehicleInput_CalculationSetting: "Configure route calculation",
         VehicleInput_UseRTTI: "Use Traffic Information",
         VehicleInput_WantManeuvers: "Detailed Route Description",
         VehicleInput_UseAlternatives: "Calculate alternative routes",
         VehicleInput_TrafficFlow: "Show traffic flow",
         VehicleInput_WithRouteLinks: "Detailed RouteLink Infos",
         VehicleInput_CurvyRoute: "Prefer curvy roads",
         VehicleInput_DevMode: "Developer mode",
         VehicleInput_VehicleSelectLabel: "Vehicle",
         VehicleInput_BatterySettingsLabel: "Battery configuration",
         VehicleInput_BatterySettingsChargeAtDeparture: "Charge at departure",
         VehicleInput_BatterySettingsMinimumChargeLevelAtDest: "Minimum charge level at destination",
         VehicleInput_BatterySettingsMinimumChargeLevel: "Minimum charge level",
         VehicleInput_BatterySettingsMaximumChargeLevel: "Maximum charge level",
         VehicleInput_BatterySettingsOtherDevicesPowerConsumption: "Other devices power consumption",
         VehicleConfig_VehicleSettings: "Vehicle",
         VehicleConfig_ChargingSettings: "charging",
         VehicleConfig_Consumption: "Consumption",
         VehicleConfig_Speed: "Speed",
         VehicleConfig_MaxEnergy: "Max energy",
         VehicleConfig_ChargingState: "Charging state",
         VehicleConfig_UsablePower: "Usable power",
         VehicleConfig_ProviderPreference: "Provider selection",
         VehicleConfig_Preferred: "Preferred",
         VehicleConfig_Excluded: "Excluded",
         VehicleConfig_Mandatory: "Mandatory",
         VehicleConfig_AddEntry: "Add Entry",
         VehicleConfig_DeleteEntry: "Delete Entry",
         VehicleConfig_ValueMustBeSet: "value must be set",
         VehicleConfig_ValueMustBeLarger: "value must be larger than preceding",
         VehicleConfig_DuplicateEntry: "Duplicate Entry",
         VehicleConfig_Provider: "Provider",
         LocationSelectPopup_SetStart: "Start here",
         LocationSelectPopup_SetWaypoint: "Set waypoint",
         LocationSelectPopup_SetDestination: "Set destination",
         LocationSelectPopup_AddDestination: "Add destination",
         LocationSelectPopup_AddUserBlockage: "Add user blockage",
         LocationSelectPopup_RemoveUserBlockage: "Remove user blockage",
         LocationSelectPopup_RemoveAllUserBlockages: "Remove all user blockages",
         Itinerary_From: "From",
         Itinerary_To: "To",
         Itinerary_TravelTime: "Travel time",
         Itinerary_TravelDistance: "Distance",
         Itinerary_TrafficDelay: "Traffic delay",
         Itinerary_Roadblocks: "There are roadblocks on the route",
         Itinerary_Start: "Start",
         Itinerary_Waypoint: "Arrival",
         ChargerInfo_Prefer: "Prefer",
         ChargerInfo_Avoid: "Avoid",
         VehicleConfig_Custom_car_brand: "Custom",
         CarSelection_Select_Car: "Select a Car",
         VehicleConfig_Battery_Cap: "Battery Cap.",
         ChargingCurve_Set_Charging_Curve: "Set Charge Curve",
         ChargingCurve_Edit_Charging_Curve: "Edit Charge Curve",
         ConnectorSelector_Select_Charging_Connectors: "Select Plugs",
         ConsumptionCurve_Edit_Consumption: "Edit Consumption",
         SupplierSelector_Select_Supplier: "Select Supplier",
         PreferredChargers_Title: "Preferred Chargers",
      },
   },
   // cspell:dictionaries:en,de-de
   de: {
      translation: {
         Generic_Close: "Schließen",
         Generic_Cancel: "Abbrechen",
         Generic_Delete: "Löschen",
         Generic_Ok: "Ok",
         Generic_All: "Alle",
         LoadingComponent_Loading: "Lade Anwendung...",
         AppBar_Main: "Übersicht",
         AppBar_Destinations: "Ziele",
         AppBar_Settings: "Einstellungen",
         AppBar_Routing: "Routenberechnung",
         AppBar_OpenSettings: "Einstellungen öffnen",
         AppBar_About: "Über",
         AppBar_Logout: "Abmelden",
         AppBar_OneCoreGoVersionInfo: "OneCoreGo Versionsinformationen",
         WelcomePage_Welcome: "Willkommen",
         WelcomePage_SignOut: "Abmelden",
         WelcomePage_CloseAccount: "Account löschen",
         DestinationPage_NewDestination: "Neues Ziel",
         DestinationPage_ClearAll: "Alle löschen",
         DestinationPage_ShowAll: "Zeige alle",
         DestinationPage_NoDestinations: "Keine Ziele gespeichert",
         SettingsPage_NewDestination: "Neue Einstellung",
         SettingsPage_ClearAll: "Alle löschen",
         SettingsPage_ShowAll: "Zeige alle",
         SettingsPage_NoDestinations: "Keine Einstellungen gespeichert",
         RoutingInput_AddDestination: "Ziel hinzufügen",
         RoutingInput_ClearRoute: "Route löschen",
         RoutingInput_PosSearch_StartPosition: "Start der Route",
         RoutingInput_PosSearch_Waypoint: "Zwischenziel",
         RoutingInput_PosSearch_Destination: "Ziel der Route",
         VehicleInput_CalculationSetting: "Routenberechnung konfigurieren",
         Avoid_HIGHWAYS_MOTORWAYS: "Autobahnen nicht verwenden",
         Avoid_TOLL_ROADS: "Mautstraßen nicht verwenden",
         Avoid_FERRY: "Fähre/Autozug nicht verwenden",
         Avoid_TUNNELS: "Tunnel nicht verwenden",
         VehicleInput_UseRTTI: "Verkehrsinformationen berücksichtigen",
         VehicleInput_WantManeuvers: "Ausführliche Wegbeschreibung",
         VehicleInput_UseAlternatives: "Routenalternativen berechnen",
         VehicleInput_TrafficFlow: "Verkehrsfluss zeigen",
         VehicleInput_WithRouteLinks: "Detaillierte RouteLink Infos",
         VehicleInput_CurvyRoute: "Kurvige Strecke bevorzugen",
         VehicleInput_DevMode: "Entwicklermodus",
         VehicleInput_VehicleSelectLabel: "Fahrzeug",
         VehicleInput_BatterySettingsLabel: "Batteriekonfiguration",
         VehicleInput_BatterySettingsChargeAtDeparture: "Ladezustand bei Abfahrt",
         VehicleInput_BatterySettingsMinimumChargeLevelAtDest: "Ladezustand am Ziel mindestens",
         VehicleInput_BatterySettingsMinimumChargeLevel: "Ladezustand mindestens",
         VehicleInput_BatterySettingsMaximumChargeLevel: "Ladezustand höchstens",
         VehicleInput_BatterySettingsOtherDevicesPowerConsumption: "Verbrauch anderer Geräte",
         VehicleConfig_VehicleSettings: "Fahrzeug",
         VehicleConfig_ChargingSettings: "Aufladen",
         VehicleConfig_Consumption: "Verbrauch",
         VehicleConfig_Speed: "Geschwindigkeit",
         VehicleConfig_MaxEnergy: "Max. Energie",
         VehicleConfig_ChargingState: "Ladezustand",
         VehicleConfig_UsablePower: "Nutzbare Leistung",
         VehicleConfig_ProviderPreference: "Anbieterauswahl",
         VehicleConfig_Preferred: "Bevorzugt",
         VehicleConfig_Excluded: "Ausgeschlossen",
         VehicleConfig_Mandatory: "Ausschließlich",
         VehicleConfig_AddEntry: "Eintrag hinzufügen",
         VehicleConfig_DeleteEntry: "Eintrag löschen",
         VehicleConfig_ValueMustBeSet: "Wert muß gesetzt sein",
         VehicleConfig_ValueMustBeLarger: "Wert muß größer als vorhergehender sein",
         VehicleConfig_DuplicateEntry: "Eintrag duplizieren",
         VehicleConfig_Provider: "Anbieter",
         LocationSelectPopup_SetStart: "Hier starten",
         LocationSelectPopup_SetWaypoint: "Zwischenziel",
         LocationSelectPopup_SetDestination: "Ziel festlegen",
         LocationSelectPopup_AddDestination: "Ziel hinzufügen",
         LocationSelectPopup_AddUserBlockage: "Benutzer Sperrung hinzufügen",
         LocationSelectPopup_RemoveUserBlockage: "Benutzer Sperrung löschen",
         LocationSelectPopup_RemoveAllUserBlockages: "Alle Benutzer Sperrungen löschen",
         Itinerary_From: "Von",
         Itinerary_To: "Nach",
         Itinerary_TravelTime: "Reisezeit",
         Itinerary_TravelDistance: "Entfernung",
         Itinerary_TrafficDelay: "Verzögerung durch Verkehr",
         Itinerary_Roadblocks: "Auf der Strecke befinden sich Straßensperrungen",
         Itinerary_Start: "Start",
         Itinerary_Waypoint: "Ankunft",
         ChargerInfo_Prefer: "Bevorzugt",
         ChargerInfo_Avoid: "Vermeiden",
         VehicleConfig_Custom_car_brand: "Benutzerdefiniert",
         CarSelection_Select_Car: "Auto auswählen",
         VehicleConfig_Battery_Cap: "Akku größe",
         ChargingCurve_Set_Charging_Curve: "Ladekurve einstellen",
         ChargingCurve_Edit_Charging_Curve: "Ladekurve ändern",
         ConnectorSelector_Select_Charging_Connectors: "Ladestecker auswählen",
         ConsumptionCurve_Edit_Consumption: "Verbrauch einstellen",
         SupplierSelector_Select_Supplier: "Stationsbetreiber auswählen",
         PreferredChargers_Title: "Bevorzugte Ladestationen",
      },
   },
}

i18n
   .use(initReactI18next)
   .use(I18nextBrowserLanguageDetector)
   .init({
      resources,
      interpolation: { escapeValue: false },
      detection: {
         caches: [], // Makes development more easy
      },
      fallbackLng: "en",
   })
   .catch(err => console.error(err))
